import axios from 'axios';
import { test_api_key, prod_api_key } from 'src/config';
import { reduxErrorMessage } from 'src/utils/helper';
export const FETCH_SUMMARY = 'FETCH_SUMMARY';
export const SET_SUMMARY = 'SET_SUMMARY';
export const FAILED_SUMMARY_FETCH = 'FAILED_SUMMARY_FETCH';

// export const NEW_REFUND_REQUEST = 'NEW_REFUND_REQUEST';
// export const NEW_REFUND_SUCCESS = 'NEW_REFUND_SUCCESS';
// export const NEW_REFUND_FAIL = 'NEW_REFUND_FAIL';

// const accessToken = 'test-ac6790bd-edee-42c4-9a58-d80f99baf35f';

export const fetchSummary = () => ({
  type: FETCH_SUMMARY
});

export const setSummary = (payload) => ({
  type: SET_SUMMARY,
  payload
});

export const setError = (payload) => ({
  type: FAILED_SUMMARY_FETCH,
  payload
});

export const getAccountSummary = () => async (dispatch) => {
  dispatch(fetchSummary());
  try {
    const key = window.localStorage.getItem('test_api_key');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`
      }
    };

    const res = await axios.get(
      'https://api.pay-now.io/api/overview/account-summary',
      config
    );
    const data = res.data.data;
    dispatch(setSummary(data));
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
