import axios from 'axios';
import {
  BASE_API_URL,
  accessToken,
  test_api_key,
  prod_api_key
} from 'src/config';
import useAxios from 'src/hooks/useAxios';
import { errorMessage } from 'src/utils/apiCalls';
import { reduxErrorMessage } from 'src/utils/helper';
export const FETCH_TRANSACTION = 'FETCH_TRANSACTION';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const FAILED_TRANSACTION_FETCH = 'FAILED_TRANSACTION_FETCH';

export const fetchWalletTransaction = () => ({
  type: FETCH_TRANSACTION
});

export const setWalletTransaction = (payload) => ({
  type: SET_TRANSACTION,
  payload
});

export const setError = (payload) => ({
  type: FAILED_TRANSACTION_FETCH,
  payload
});

export const getAllWalletTransaction = (link = '/wallet-payment') => async (
  dispatch
) => {
  const req = useAxios(test_api_key);
  dispatch(fetchWalletTransaction());
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };

    const res = await req.get(link);
    const data = res.data;
    dispatch(setWalletTransaction(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getWalletPayment = async (walletPaymentId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };
    const response = await axios.get(
      `https://api.pay-now.io/api/wallet-payment/${walletPaymentId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
