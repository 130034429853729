import axios from "axios";
import { BASE_API_URL, accessToken } from "src/config";
import { reduxErrorMessage } from "src/utils/helper";
export const FETCH_KYB = "FETCH_KYB";
export const SET_KYB = "SET_KYB";
export const FAILED_KYB_FETCH = "FAILED_KYB_FETCH";

export const fetchKyb = () => ({
  type: FETCH_KYB,
});

export const setKyb = (payload) => ({
  type: SET_KYB,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_KYB_FETCH,
  payload,
});

export const getAllKyb = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getCustSubmitted = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb?status=CUST_SUBMITTED`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getNotSubmitted = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb?status=NOT_STARTED`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getApproved = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb?status=APPROVED`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getRejected = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb?status=REJECTED`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getToBeSubmitted = (limitLink = "") => async (dispatch) => {
  dispatch(fetchKyb());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let API_URL = `${BASE_API_URL}/backoffice/kyb?status=TO_BE_RESUBMITTED`;
    if (limitLink) {
      API_URL = `${BASE_API_URL}/backoffice${limitLink}`;
    }
    const res = await axios.get(`${API_URL}`, config);
    const data = res.data;
    dispatch(setKyb(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};
