import { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SidebarLayout from "src/layouts";
import SuspenseLoader from "src/components/SuspenseLoader";
import Guest from "src/components/Guest";
import Authenticated from "src/components/Authenticated";

export const renderRoutes = (routes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  // preview
  {
    path: "/preview",
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/preview/dashboards/analytics",
        component: lazy(() => import("src/content/dashboards/Analytics")),
      },
      {
        exact: true,
        path: "/preview/dashboards/profile",
        component: lazy(() => import("src/content/dashboards/Profile")),
      },
      {
        exact: true,
        path: "/preview/dashboards/add-admin",
        component: lazy(() =>
          import("src/content/dashboards/Accounts/AddAccount")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-accounts",
        component: lazy(() => import("src/content/dashboards/Accounts")),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invest-account-activation",
        component: lazy(() =>
          import(
            "src/content/dashboards/InternalTreasury/InvestAccountActivation"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/add-balance",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/update")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/AddBankAccount",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/AddBankAccount")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/conversion",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Conversion/index")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/investment",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Investment")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/investment/AddHoldings",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Investment/Holding")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/investment/:asset_id",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Investment/Asset")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/:connector_name",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Connector")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/internal-treasury/:connector_name/:organization_id/sub-account-summary",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/SubAccountCurrency")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/currency/:currency",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Currency")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/country/:country",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Country")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/internal-treasury/:connector_name/:currency",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Currency")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/internal-treasury/country/:connector_name/:country",
        component: lazy(() =>
          import("src/content/dashboards/InternalTreasury/Country")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/organization",
        component: lazy(() =>
          import("src/content/dashboards/Organizations/index")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/organization/:orgId",
        component: lazy(() =>
          import("src/content/dashboards/Organizations/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invite-organization",
        component: lazy(() =>
          import("src/content/dashboards/Organizations/InviteOrg")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb",
        component: lazy(() => import("src/content/dashboards/KYBs")),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/all-orgs",
        component: lazy(() => import("src/content/dashboards/KYBs/AllOrgs")),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/in-review",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/InReview")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/not-started",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/NotStarted")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/cust-submitted",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/CustSubmitted")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/resubmitted",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/Resubmitted")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/approved",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/Approved")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/rejected",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/Rejected")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/to-be-resubmitted",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/Status/ToBeSubmitted")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/kyb/:kybId",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/documents/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Contract/AllOrgDocs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/contract/orgs",
        component: lazy(() => import("src/content/dashboards/Contract/Orgs")),
      },
      {
        exact: true,
        path: "/preview/dashboards/documents/org-specific-contract/:org_id",
        component: lazy(() => import("src/content/dashboards/Contract")),
      },
      {
        exact: true,
        path: "/preview/dashboards/documents/org-specific-docs/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/Contract/OrgDocs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Payin/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin/org-specific-payins/:org_id",
        component: lazy(() => import("src/content/dashboards/Payin")),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payins",
        component: lazy(() => import("src/content/dashboards/Payin")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transfers",
        component: lazy(() => import("src/content/dashboards/Transfers")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transfers/create",
        component: lazy(() =>
          import("src/content/dashboards/Transfers/CreateTransfer")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transfer/:transfer_id",
        component: lazy(() =>
          import("src/content/dashboards/Transfers/MoreDetails/index")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin/:org_id/:payinId",
        component: lazy(() =>
          import("src/content/dashboards/Payin/MoreDetails")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/invest-account-activation/:org_id/:interfaceId",
        component: lazy(() =>
          import(
            "src/content/dashboards/InternalTreasury/InvestAccountActivation/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payins",
        component: lazy(() => import("src/content/dashboards/Payin")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin/:payinId",
        component: lazy(() =>
          import("src/content/dashboards/Payin/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/screening-report/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/KYBs/MoreDetails/OrgScreeningReport")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin-method/:payinMethodId",
        component: lazy(() =>
          import("src/content/dashboards/PayinMethod/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin-method",
        component: lazy(() =>
          import("src/content/dashboards/PayinMethod/newPayinMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-payin-method/:payinMethodId",
        component: lazy(() =>
          import("src/content/dashboards/PayinMethod/EditPayinMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payin-methods",
        component: lazy(() => import("src/content/dashboards/PayinMethod")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-method/:payoutMethodId",
        component: lazy(() =>
          import("src/content/dashboards/PayoutMethod/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout-method",
        component: lazy(() =>
          import("src/content/dashboards/PayoutMethod/newPayoutMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-payout-method/:payoutMethodId",
        component: lazy(() =>
          import("src/content/dashboards/PayoutMethod/EditPayoutMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payout-methods",
        component: lazy(() => import("src/content/dashboards/PayoutMethod")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payment-method-setting/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/PaymentMethodSetting")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/checkout/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Checkout/orgSpecific")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/checkout/org-specific-checkouts/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/Checkout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-checkouts",
        component: lazy(() => import("src/content/dashboards/Checkout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/checkout/:org_id/:checkoutId",
        component: lazy(() =>
          import("src/content/dashboards/Checkout/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-adjustments",
        component: lazy(() => import("src/content/dashboards/Adjustment")),
      },
      {
        exact: true,
        path: "/preview/dashboards/adjustment-orgs",
        component: lazy(() =>
          import("src/content/dashboards/Adjustment/orgSpecific")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/adjustment/org-specific-adjustment/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/Adjustment")),
      },
      {
        exact: true,
        path: "/preview/dashboards/adjustment/:adjust_id/:env",
        component: lazy(() =>
          import("src/content/dashboards/Adjustment/moreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-adjustment",
        component: lazy(() =>
          import("src/content/dashboards/Adjustment/newAdjustment")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-adjustment/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/Adjustment/newAdjustment")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/fees-adjustment-orgs",
        component: lazy(() =>
          import("src/content/dashboards/FeesAdjustment/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-fees-adjustments",
        component: lazy(() => import("src/content/dashboards/FeesAdjustment")),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/fees-adjustment/org-specific-fees-adjustment/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/FeesAdjustment")),
      },
      {
        exact: true,
        path: "/preview/dashboards/fees-adjustment/:fees_adjustment_id/:env",
        component: lazy(() =>
          import("src/content/dashboards/FeesAdjustment/moreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-fees-adjustment",
        component: lazy(() =>
          import("src/content/dashboards/FeesAdjustment/newFeesAdjustment")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-fees-adjustment/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/FeesAdjustment/newFeesAdjustment")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallet/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/orgSpecific")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/wallet/org-specific-wallets/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/Wallet")),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-wallets",
        component: lazy(() => import("src/content/dashboards/Wallet")),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallet/:org_id/:walletId",
        component: lazy(() =>
          import("src/content/dashboards/Wallet/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-wallet-fund-transfers",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallet-fund-transfer/orgs",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/wallet-fund-transfer/:wft_id",
        component: lazy(() =>
          import("src/content/dashboards/WalletFundTransfer/moreDetails")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/wallet-fund-transer/org-specific-wallet-fund-transfer/:org_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/WalletFundTransfer/orgSpecific/orgWalletFundTransfer"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/customer/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Customer/orgSpecific")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/customer/org-specific-customers/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/Customer")),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-wallet-fund-transfer",
        component: lazy(() =>
          import(
            "src/content/dashboards/WalletFundTransfer/NewWalletFundTransfer"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-wallet-fund-transfer/:org_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/WalletFundTransfer/NewWalletFundTransfer"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-customers",
        component: lazy(() => import("src/content/dashboards/Customer")),
      },
      {
        exact: true,
        path: "/preview/dashboards/customer/:org_id/:customerId",
        component: lazy(() =>
          import("src/content/dashboards/Customer/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/orgs",
        component: lazy(() => import("src/content/dashboards/OrgUsers")),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/impersonate",
        component: lazy(() =>
          import("src/content/dashboards/OrgUsers/ImpersonateUser")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/org-specific-users/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgUsers/orgUsers")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/user/:org_id/:user_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgUsers/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/organizations",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/OrgPricing")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/org-pricing-at-a-glance/:org_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/OrgPricing/OrgPricingDetails/OrgPricing"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plans",
        component: lazy(() => import("src/content/dashboards/Pricing/Plans")),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan/plan-details/:plan_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/Plans/PlanDetails/PlanPricing")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plans/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/Plans/AddPlan")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plans/edit/:plan_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/Plans/EditPlan")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan-association",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PlanAssociation")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan-association/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PlanAssociation/AddPlanAssoc")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/plan-association/edit/:plan_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PlanAssociation/EditPlanAssoc")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-plan",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryPlan")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-plan/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryPlan/AddCountryPlan")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-plan/edit/:cp_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryPlan/EditCountryPlan")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-tax",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryTax")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-tax/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryTax/AddCountryTax")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/country-tax/edit/:ct_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CountryTax/EditCountryTax")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/connector/key-connector-map",
        component: lazy(() => import("src/content/dashboards/KeyConnectorMap")),
      },
      {
        exact: true,
        path: "/preview/dashboards/connector/key-connector-map/add",
        component: lazy(() =>
          import("src/content/dashboards/KeyConnectorMap/AddKeyConnectorMap")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/connector/key-connector-map/edit/:key_connector_map_id",
        component: lazy(() =>
          import("src/content/dashboards/KeyConnectorMap/EditKeyConnectorMap")
        ),
      },

      {
        exact: true,
        path: "/preview/dashboards/pricing/organization-tax",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/OrganizationTax")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/organization-tax/add",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/OrganizationTax/AddOrganizationTax"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/organization-tax/edit/:org_tax_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/OrganizationTax/EditOrganizationTax"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/monthly-minimum",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/MonthlyMin")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/monthly-minimum/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/MonthlyMin/AddMonthlyMin")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/monthly-minimum/edit/:mm_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/MonthlyMin/EditMonthlyMin")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/recurring-fees",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/RecurringFees")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/recurring-fees/add",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/RecurringFees/AddRecurringFees"
          )
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/recurring-fees/edit/:recurring_fee_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/RecurringFees/EditRecurringFees"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/fx-conversion-markup",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/FxConversionMarkup")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/fx-conversion-markup/add",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/FxConversionMarkup/AddFxConversionMarkup"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/fx-conversion-markup/edit/:cmpId",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/FxConversionMarkup/EditFxConversionMarkup"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/fx-conversion-markup/:cmpId",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/FxConversionMarkup/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/fx-conversion-markup-tier/add/:cmpId",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/FxConversionMarkup/MoreDetails/cmpTier/AddCmpTier"
          )
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/fx-conversion-markup-tier/edit/:cmpId/:tier_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/FxConversionMarkup/MoreDetails/cmpTier/EditCmpTier"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payin-method-pricing",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayinMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payin-method-pricing/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayinMethod/AddPayinMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payin-method-pricing/edit/:pmpId",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayinMethod/EditPayinMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payin-method-pricing/:pmpId",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayinMethod/MoreDetails")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/payin-method-pricing-tier/add/:pmp_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayinMethod/MoreDetails/PmPricingTier/AddPmPricingTier"
          )
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/payin-method-pricing-tier/edit/:pmp_id/:tier_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayinMethod/MoreDetails/PmPricingTier/EditPmPricingTier"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payin-method-pricing-tier/:tier_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayinMethod/MoreDetails/PmPricingTier/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payout-method-pricing",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayoutMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payout-method-pricing/add",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayoutMethod/AddPayoutMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payout-method-pricing/edit/:pompId",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayoutMethod/EditPayoutMethod")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payout-method-pricing/:pompId",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PayoutMethod/MoreDetails")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/payout-method-pricing-tier/add/:pomp_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayoutMethod/MoreDetails/PmPricingTier/AddPmPricingTier"
          )
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/payout-method-pricing-tier/edit/:pomp_id/:tier_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayoutMethod/MoreDetails/PmPricingTier/EditPmPricingTier"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/payout-method-pricing-tier/:tier_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PayoutMethod/MoreDetails/PmPricingTier/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/pricing-setup/create",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PricingSetup")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/pricing-setup",
        component: lazy(() =>
          import(
            "src/content/dashboards/Pricing/PricingSetup/AllPricingSetupTable"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/pricing-setup/:pricing_setup_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PricingSetup")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/pricing/pricing-setup/:pricing_setup_id/edit",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/PricingSetup/EditPricing")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pricing/cost-config",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/CostConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/orgs",
        component: lazy(() => import("src/content/dashboards/Invoice/AllOrgs")),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/org-specific-invoices/:org_id",
        component: lazy(() => import("src/content/dashboards/Invoice")),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/invoice-config",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/InvoiceConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/invoice-config/add",
        component: lazy(() =>
          import(
            "src/content/dashboards/Invoice/InvoiceConfig/AddInvoiceConfig"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/dashboard",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/InvoiceDashboard")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/raise",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/InvoiceDashboard/RaiseInvoice")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/invoice-config/edit/:org_id",
        component: lazy(() =>
          import(
            "src/content/dashboards/Invoice/InvoiceConfig/EditInvoiceConfig"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/:org_id/:invoiceId",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/invoice/overview",
        component: lazy(() =>
          import("src/content/dashboards/Invoice/InvoiceAtGlance")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/connector",
        component: lazy(() =>
          import("src/content/dashboards/Connector/newConnector")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/edit-connector/:connectorId",
        component: lazy(() =>
          import("src/content/dashboards/Connector/EditConnector")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-connectors",
        component: lazy(() => import("src/content/dashboards/Connector")),
      },
      {
        exact: true,
        path: "/preview/dashboards/connector/:connectorId",
        component: lazy(() =>
          import("src/content/dashboards/Connector/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-org-connector-configs",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-connector-config/orgs",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-connector-config/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig/Orgs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-connector-config/:org_id/:orgConfigId",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-org-connector-config",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig/NewOrgConfig")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/edit-org-connector-config/:org_id/:orgConfigId",
        component: lazy(() =>
          import("src/content/dashboards/OrgConnectorConfig/EditOrgConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payin-method-configs",
        component: lazy(() => import("src/content/dashboards/OrgPayinConfig")),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-specific-payin-method-config/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgPayinConfig/OrgSpecificPayinConfig")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/org-payin-method-config/:org_id/:orgConfigId",
        component: lazy(() =>
          import("src/content/dashboards/OrgPayinConfig/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/new-payin-method-config",
        component: lazy(() =>
          import("src/content/dashboards/OrgPayinConfig/NewOrgConfig")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/edit-payin-method-config/:org_id/:orgConfigId",
        component: lazy(() =>
          import("src/content/dashboards/OrgPayinConfig/EditOrgConfig")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payin-method-config/orgs",
        component: lazy(() =>
          import("src/content/dashboards/OrgPayinConfig/Orgs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-at-glance/orgs",
        component: lazy(() => import("src/content/dashboards/OrgGlance/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-at-glance/orgs/org-specific/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgGlance/OrgView/")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-control-at-glance/orgs",
        component: lazy(() => import("src/content/dashboards/OrgControl")),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner-config/all-partners",
        component: lazy(() => import("src/content/dashboards/PartnerControl")),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/org-control-at-glance/orgs/org-specific/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/OrgControl/ControlPanel")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner-config/all-partners/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/PartnerControl/ControlPanel")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/org-balance-at-glance",
        component: lazy(() => import("src/content/dashboards/OrgBalance/")),
      },
      {
        exact: true,
        path: "/preview/dashboards/virtual-account/orgs",
        component: lazy(() =>
          import("src/content/dashboards/VirtualAccount/orgSpecific")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/virtual-account/org-specific/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/VirtualAccount")),
      },
      {
        exact: true,
        path: "/preview/dashboards/virtual-accounts",
        component: lazy(() => import("src/content/dashboards/VirtualAccount")),
      },
      {
        exact: true,
        path: "/preview/dashboards/virtual-account/:org_id/:virtualAccId",
        component: lazy(() =>
          import("src/content/dashboards/VirtualAccount/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/reconciliation-batch",
        component: lazy(() => import("src/content/dashboards/Recon")),
      },
      {
        exact: true,
        path: "/preview/dashboards/create-reconciliation-job",
        component: lazy(() =>
          import(
            "src/content/dashboards/Reconciliation/ReconJobs/CreateReconJob"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/reconciliation-job",
        component: lazy(() =>
          import("src/content/dashboards/Reconciliation/ReconJobs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/reconciliation-job/:job_id",
        component: lazy(() =>
          import("src/content/dashboards/Reconciliation/ReconJobs/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/reconciliation-batches/:job_id",
        component: lazy(() =>
          import("src/content/dashboards/Reconciliation/ReconBatches")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-recons/:job_id/:batch_id",
        component: lazy(() =>
          import("src/content/dashboards/Reconciliation/Recons")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/reconciliation-batch/:batchId",
        component: lazy(() =>
          import("src/content/dashboards/Recon/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-settlements",
        component: lazy(() =>
          import("src/content/dashboards/Settlements/Settlements")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-payout-details",
        component: lazy(() =>
          import("src/content/dashboards/Settlements/SettlementDetail")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Settlements/Orgs")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement/org-specific-settlements/:org_id",
        component: lazy(() => import("src/content/dashboards/Settlements")),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement/:org_id/:settlementId",
        component: lazy(() =>
          import("src/content/dashboards/Settlements/Summary")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-transaction/:org_id/:trxnId",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settlements/Summary/SettlementTrxn/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-payout/:org_id/:payoutId",
        component: lazy(() =>
          import(
            "src/content/dashboards/Settlements/SettlementPayouts/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-org-config/:org_id",
        component: lazy(() => import("src/content/dashboards/SOC")),
      },
      {
        exact: true,
        path: "/preview/dashboards/settlement-org-config/:org_id/:orgSOCID",
        component: lazy(() => import("src/content/dashboards/SOC/MoreDetails")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/Transactions")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/stables-transaction",
        component: lazy(() =>
          import("src/content/dashboards/StableTransactions")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/stables-transaction/create",
        component: lazy(() =>
          import(
            "src/content/dashboards/StableTransactions/createStablesTransaction"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/stables-transaction/ramps/:ramp_id",
        component: lazy(() =>
          import("src/content/dashboards/StableTransactions/moreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/provider",
        component: lazy(() => import("src/content/dashboards/Provider")),
      },
      {
        exact: true,
        path: "/preview/dashboards/provider/create",
        component: lazy(() => import("src/content/dashboards/Provider/Create")),
      },
      {
        exact: true,
        path: "/preview/dashboards/provider/:provider_id",
        component: lazy(() =>
          import("src/content/dashboards/Provider/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/Orgs")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/transactions/org-specific-transactions/:environment/:org_id",
        component: lazy(() => import("src/content/dashboards/Transactions")),
      },
      {
        exact: true,
        path: "/preview/dashboards/transactions/:org_id/:transactionID",
        component: lazy(() =>
          import("src/content/dashboards/Transactions/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transaction-rules/new-transaction-rule",
        component: lazy(() =>
          import("src/content/dashboards/TransactionMonitor/NewTransactionRule")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transaction-rules/all-transaction-rules",
        component: lazy(() =>
          import("src/content/dashboards/TransactionMonitor")
        ),
      },
      // {
      //   exact: true,
      //   path: "/preview/dashboards/alert-details/:transactionId",
      //   component: lazy(() =>
      //     import(
      //       "src/content/dashboards/Monitoring/TransactionMonitoring/Alerts/AlertsTable/AlertDetails"
      //     )
      //   ),
      // },
      {
        exact: true,
        path: "/preview/dashboards/pending-actions/settlement-bank",
        component: lazy(() =>
          import("src/content/dashboards/PendingActions/SettlementBank")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pending-actions/payment-method-setting",
        component: lazy(() =>
          import("src/content/dashboards/PendingActions/PaymentMethodSetting")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/pending-actions/inbound-fund-notice",
        component: lazy(() =>
          import("src/content/dashboards/PendingActions/InboundFundNotice")
        ),
      },
      // {
      //   exact: true,
      //   path: "/preview/dashboards/pending-actions/payout-method",
      //   component: lazy(() =>
      //     import("src/content/dashboards/PendingActions/PayoutMethod")
      //   ),
      // },
      {
        exact: true,
        path: "/preview/dashboards/transaction-monitoring/:screen",
        component: lazy(() =>
          import("src/content/dashboards/Compliance/TransactionMonitoring")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/list-manager/:list_type",
        component: lazy(() =>
          import("src/content/dashboards/Compliance/ListManager")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/daily-screening/:screen",
        component: lazy(() =>
          import("src/content/dashboards/Compliance/DailyScreening")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/broadcast-email",
        component: lazy(() => import("src/content/dashboards/BroadcastEmail")),
      },
      {
        exact: true,
        path: "/preview/dashboards/broadcast-email/history",
        component: lazy(() =>
          import("src/content/dashboards/BroadcastEmail/History")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/broadcast-email/:broadcast_email_id",
        component: lazy(() =>
          import("src/content/dashboards/BroadcastEmail/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-scheduler-repositories",
        component: lazy(() => import("src/content/dashboards/Scheduler")),
      },
      {
        exact: true,
        path: "/preview/dashboards/create-job-scheduler",
        component: lazy(() =>
          import("src/content/dashboards/Scheduler/CreateSchedulerRepo")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/finmo-api",
        component: lazy(() => import("src/content/dashboards/Api")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Payout/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout/org-specific-payouts/:org_id",
        component: lazy(() => import("src/content/dashboards/Payout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/all-payouts",
        component: lazy(() => import("src/content/dashboards/Payout")),
      },
      {
        exact: true,
        path: "/preview/dashboards/payout/:org_id/:payoutId",
        component: lazy(() =>
          import("src/content/dashboards/Payout/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail/partners",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Partner")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/audit-trail/partner-specific/:partner_org_id",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Partner/AuditTrailTable")
        ),
      },
      {
        exact: true,
        path:
          "/preview/dashboards/audit-trail/partner-specific/:partner_org_id/:audit_id",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Partner/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail/orgs",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Organization")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail/org-specific/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Organization/AllOrgsTrails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/audit-trail/org-specific/:org_id/:audit_id",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/Organization/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/bo-audit-trail",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/BackOffice")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/bo-audit-trail/:auditId",
        component: lazy(() =>
          import("src/content/dashboards/AuditTrail/BackOffice/MoreDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/transaction-lookup",
        component: lazy(() =>
          import("src/content/dashboards/TransactionLookup")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/regulatory-reporting/ifti-incoming",
        component: lazy(() =>
          import("src/content/dashboards/RegulatoryReporting/IFTI/iftiIncoming")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/regulatory-reporting/ifti-outgoing",
        component: lazy(() =>
          import("src/content/dashboards/RegulatoryReporting/IFTI/iftiOutgoing")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/regulatory-reporting/mas-reporting",
        component: lazy(() =>
          import("src/content/dashboards/RegulatoryReporting/MAS")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner",
        component: lazy(() => import("src/content/dashboards/Partner")),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner/invite",
        component: lazy(() =>
          import("src/content/dashboards/Partner/InvitePartner")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner/:partner_id",
        component: lazy(() =>
          import("src/content/dashboards/Partner/PartnerDetails")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/partner/:partner_id/all-merchants",
        component: lazy(() =>
          import("src/content/dashboards/Partner/PartnerMerchants")
        ),
      },
      {
        path: "/preview/dashboards/monthly-minimum/orgs",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/MonthlyMin/orgSpecific")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/monthly-minimum/org-specific/:org_id",
        component: lazy(() =>
          import("src/content/dashboards/Pricing/MonthlyMin")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/disputed-transactions",
        component: lazy(() =>
          import("src/content/dashboards/TransactionLookup/DisputedTxns")
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/dispute/:disputeId",
        component: lazy(() =>
          import(
            "src/content/dashboards/TransactionLookup/DisputeTxns/MoreDetails"
          )
        ),
      },
      {
        exact: true,
        path: "/preview/dashboards/customer-screening/:screen",
        component: lazy(() =>
          import("src/content/dashboards/Compliance/CustomerScreening")
        ),
      },
    ],
  },
  {
    exact: true,
    path: "/",
    component: () => <Redirect to="/preview/dashboards/analytics" />,
  },
  // login
  {
    exact: true,
    guard: Guest,
    path: "/login",
    component: lazy(() => import("src/content/pages/Auth/Login/Cover")),
  },
  {
    exact: true,
    guard: Guest,
    path: "/register",
    component: lazy(() => import("src/content/pages/Auth/Register/Cover")),
  },

  {
    exact: true,
    path: "/recover-password",
    component: lazy(() => import("src/content/pages/Auth/RecoverPassword")),
  },
  {
    exact: true,
    path: "/reset-password",
    component: lazy(() => import("src/content/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/email-verification",
    component: lazy(() => import("src/content/pages/Auth/EmailVerification")),
  },
  {
    exact: true,
    path: "/email-verify",
    component: lazy(() =>
      import("src/content/pages/Auth/EmailVerification/VerifyEmail")
    ),
  },
  {
    exact: true,
    path: "/status/404",
    component: lazy(() => import("src/content/pages/Status/Status404")),
  },
  {
    exact: true,
    path: "/status/500",
    component: lazy(() => import("src/content/pages/Status/Status500")),
  },
  {
    exact: true,
    path: "/status/coming-soon",
    component: lazy(() => import("src/content/pages/Status/ComingSoon")),
  },
  {
    exact: true,
    path: "/status/maintenance",
    component: lazy(() => import("src/content/pages/Status/Maintenance")),
  },
  {
    exact: true,
    path: "*",
    component: lazy(() => import("src/content/pages/Status/Status404")),
  },

  // {
  //   path: '*',
  //   layout: BaseLayout,
  //   routes: [
  //     // {
  //     //   exact: true,
  //     //   path: '/',
  //     //   component: Overview
  //     // },
  //     {
  //       component: () => <Redirect to="/" />
  //     }
  //   ]
  // }
];

export default routes;
