import axios from 'axios';
import { BASE_API_URL, test_api_key } from 'src/config';
import useAxios from 'src/hooks/useAxios';
import { reduxErrorMessage } from 'src/utils/helper';
import { errorMessage } from 'src/utils/apiCalls';
export const FETCH_WEBHOOK = 'FETCH_WEBHOOK';
export const SET_WEBHOOK = 'SET_WEBHOOK';
export const FAILED_WEBHOOK_FETCH = 'FAILED_WEBHOOK_FETCH';

export const fetchWebhook = () => ({
  type: FETCH_WEBHOOK
});

export const setWebhook = (payload) => ({
  type: SET_WEBHOOK,
  payload
});

export const setError = (payload) => ({
  type: FAILED_WEBHOOK_FETCH,
  payload
});

export const getAllWebhook = (link = '/webhook-notification') => async (
  dispatch
) => {
  const req = useAxios(test_api_key);
  dispatch(fetchWebhook());
  try {
    const res = await req.get(link);
    const data = res.data;
    dispatch(setWebhook(data));
    return res;
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getWebhook = async (webhookId) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${test_api_key}`
      }
    };
    const response = await axios.get(
      `https://api.pay-now.io/api/webhook-notification/${webhookId}`,
      config
    );
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
