import {
    FETCH_PAYMENT,
    SET_PAYMENT,
    FAILED_PAYMENT_FETCH,
  } from '../actions/payinAction';
  
  const initalState = {
    fetching: false,
    payins: [],
    error: null
  };
  
  export const payinReducer = (state = initalState, { type, payload }) => {
    switch (type) {
      case FETCH_PAYMENT:
        return { fetching: true, payins: [], error: null };
      case SET_PAYMENT:
        return { ...state, fetching: false, payins: payload, error: null };
      case FAILED_PAYMENT_FETCH:
        return { ...state, fetching: false, payins: [], error: payload };
      default:
        return state;
    }
  };
  