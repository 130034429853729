import axios from "axios";
import { BASE_API_URL, accessToken } from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { reduxErrorMessage } from "src/utils/helper";
export const FETCH_PAYMENT = "FETCH_PAYMENT";
export const SET_PAYMENT = "SET_PAYMENT";
export const FAILED_PAYMENT_FETCH = "FAILED_PAYMENT_FETCH";

export const fetchPayin = () => ({
  type: FETCH_PAYMENT,
});

export const setPayin = (payload) => ({
  type: SET_PAYMENT,
  payload,
});

export const setError = (payload) => ({
  type: FAILED_PAYMENT_FETCH,
  payload,
});

export const getAllPayin = (limitLink = "") => async (dispatch) => {
  dispatch(fetchPayin());
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let URL = `${BASE_API_URL}/torc/payin`;
    if (limitLink) {
      URL = `${BASE_API_URL}/torc${limitLink}`;
    }
    const res = await axios.get(URL, config);
    const data = res.data;
    dispatch(setPayin(data));
  } catch (error) {
    const { message } = reduxErrorMessage(error);
    dispatch(setError(message));
  }
};

export const getOrgSpecific = async (org_id) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "x-org-id": org_id,
      },
    };
    const response = await axios.get(`${BASE_API_URL}/torc/payin`, config);
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};
