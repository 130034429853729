import axios from "axios";
import { BASE_API_URL, accessToken } from "src/config";
import { errorMessage } from "src/utils/apiCalls";
import { apiErrorMessage, toastNotification } from "src/utils/helper";

export const NEW_CONNECTOR_REQUEST = "NEW_CONNECTOR_REQUEST";
export const NEW_CONNECTOR_SUCCESS = "NEW_CONNECTOR_SUCCESS";
export const CONNECTOR_RESET = "CONNECTOR_RESET";

export const connectorReset = () => async (dispatch) =>
  dispatch({ type: CONNECTOR_RESET });

export const getAllConnector = async (limit, page) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let URL = `${BASE_API_URL}/torc/connector`;
    if (limit && page) {
      URL = `${BASE_API_URL}/torc/connector?limit=${limit}&page=${page}`;
    }
    const response = await axios.get(URL, config);
    const res = response.data;
    return res;
  } catch (error) {
    return errorMessage(error);
  }
};

export const newConnector = (connector_name, connector_config) => async (
  dispatch
) => {
  try {
    dispatch({ type: NEW_CONNECTOR_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const { data } = await axios.post(
      `${BASE_API_URL}/torc/connector`,
      { connector_name, connector_config },
      config
    );
    dispatch({
      type: NEW_CONNECTOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    toastNotification("error", apiErrorMessage(error), 5000);
    return errorMessage(error);
  }
};
