import { FETCH_KYB, SET_KYB, FAILED_KYB_FETCH } from "../actions/kybAction";

const initalState = {
  fetching: false,
  kybs: {},
  error: null,
};

export const kybReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_KYB:
      return { fetching: true, kybs: {}, error: null };
    case SET_KYB:
      return { ...state, fetching: false, kybs: payload, error: null };
    case FAILED_KYB_FETCH:
      return { ...state, fetching: false, kybs: {}, error: payload };

    default:
      return state;
  }
};
