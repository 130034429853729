export const DATE_CONVERSION_FORMAT = "EEE, dd MMM yyyy, ppp";
export const DATETIME_TO_DATE_FORMAT = "yyyy-MM-dd";
export const recurring_fees_date_limit = new Date(2037, 11, 31);

export const column_setting_keys = {
  ORGANIZATION_COLUMNS: "organization_columns",
  KYB_COLUMNS: "kyb_columns",
  PAYIN_COLUMNS: "payin_columns",
  VIRTUAL_ACCOUNT_COLUMNS: "virtual_account_columns",
  CHECKOUT_COLUMNS: "checkout_columns",
  BO_ACCOUNT_COLUMNS: "bo_account_columns",
  CUSTOMER_COLUMNS: "customer_columns",
  WALLET_COLUMNS: "wallet_columns",
  TRANSACTION_COLUMNS: "transaction_columns",
  STABLES_TRANSACTION_COLUMNS: "stables_transaction_columns",
  PROVIDER_COLUMNS: "provider_columns",
  SETTLEMENT_COLUMNS: "settlement_columns",
  SETTLEMENT_PAYOUT_COLUMNS: "settlement_payout_columns",
  SCHEDULER_REPOSITORY_COLUMNS: "scheduler_repository_columns",
  PAYIN_METHOD_CONFIG_COLUMNS: "payin_method_config_columns",
  INVOICING_COLUMNS: "invoicing_columns",
  PAYOUT_METHOD_COLUMNS: "payout_method_columns",
  PAYIN_METHOD_COLUMNS: "payin_method_columns",
  TRANSACTION_MONITORING_COLUMNS: "transaction_monitoring_columns",
  WFT_COLUMNS: "wft_columns",
  PAYOUT_COLUMNS: "payout_columns",
  WALLET_BALANCE_COLUMNS: "wallet_balance_columns",
  AUDIT_TRAIL_COLUMNS: "audit_trail_columns",
  BO_AUDIT_TRAIL_COLUMNS: "bo_audit_trail_columns",
  PARTNER_AUDIT_TRAIL_COLUMNS: "partner_audit_trail_columns",
  RECON_JOB_COLUMNS: "recon_job_columns",
  RECON_BATCH_COLUMNS: "recon_batch_columns",
  RECONCILIATION_ENTITY_COLUMNS: "reconciliation_entity_columns",
  ADJUSTMENT_COLUMNS: "adjustment_columns",
  FEES_ADJUSTMENT_COLUMNS: "fees_adjustment_columns",
  PRICING_SETUP_COLUMNS: "pricing_setup_columns",
  PARTNER_COLUMNS: "partner_columns",
  ALERT_COLUMNS: "alert_columns",
  TRANSFER_COLUMNS: "transfer_columns",
  BROADCAST_EMAIL_COLUMNS: "broadcast_email_columns",
  INVOICE_CONFIG_COLUMNS: "invoice_config_columns",
  INVEST_ACCOUNT_COLUMNS: "invest_account_columns",
  INVOICE_COLUMNS: "invoice_columns",
};

export const CRON_VALIDATION_REGEX = /^(((\*|(\d\d?))(\/\d\d?)?)|(\d\d?\-\d\d?))(,(((\*|(\d\d?))(\/\d\d?)?)|(\d\d?\-\d\d?)))*\s(((\*|(\d\d?))(\/\d\d?)?)|(\d\d?\-\d\d?))(,(((\*|(\d\d?))(\/\d\d?)?)|(\d\d?\-\d\d?)))*\s(\?|(((\*|(\d\d?L?))(\/\d\d?)?)|(\d\d?L?\-\d\d?L?)|L|(\d\d?W))(,(((\*|(\d\d?L?))(\/\d\d?)?)|(\d\d?L?\-\d\d?L?)|L|(\d\d?W)))*)\s(((\*|(\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))(\/\d\d?)?)|((\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\-(\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))(,(((\*|(\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))(\/\d\d?)?)|((\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\-(\d|10|11|12|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))))*\s(((\*|([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?)(\/\d\d?)?)|(([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?\-([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?)|([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?#([1-5]))(,(((\*|([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?)(\/\d\d?)?)|(([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?\-([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?)|([0-7]|MON|TUE|WED|THU|FRI|SAT|SUN)L?#([1-5])))*$/;

export const LIMIT_OFFSET = 1000000;
export const ORG_FILTER_LIMIT = 1500;

export const wallet_scope = [
  {
    value: "master",
    label: "ORGANIZATION",
  },
  {
    value: "customer",
    label: "CUSTOMER",
  },
];

export const wft_allowed_currency_list = [
  "AUD",
  "PHP",
  "THB",
  "NZD",
  "SGD",
  "GBP",
  "EUR",
];

export const currency_decimal = {
  AED: 2,
  AFN: 2,
  ALL: 2,
  AMD: 2,
  ANG: 2,
  AOA: 2,
  ARS: 2,
  AUD: 2,
  AWG: 2,
  AZN: 2,
  BAM: 2,
  BBD: 2,
  BDT: 2,
  BGN: 2,
  BHD: 3,
  BIF: 0,
  BMD: 2,
  BND: 2,
  BOB: 2,
  BOV: 2,
  BRL: 2,
  BSD: 2,
  BTN: 2,
  BWP: 2,
  BYR: 0,
  BZD: 2,
  CAD: 2,
  CDF: 2,
  CHE: 2,
  CHF: 2,
  CHW: 2,
  CLF: 0,
  CLP: 0,
  CNH: 2,
  CNY: 2,
  COP: 2,
  COU: 2,
  CRC: 2,
  CUC: 2,
  CUP: 2,
  CVE: 0,
  CZK: 2,
  DJF: 0,
  DKK: 2,
  DOP: 2,
  DZD: 2,
  EGP: 2,
  ERN: 2,
  ETB: 2,
  EUR: 2,
  FJD: 2,
  FKP: 2,
  GBP: 2,
  GEL: 2,
  GHS: 2,
  GIP: 2,
  GMD: 2,
  GNF: 0,
  GTQ: 2,
  GYD: 2,
  HKD: 2,
  HNL: 2,
  HRK: 2,
  HTG: 2,
  HUF: 2,
  IDR: 2,
  ILS: 2,
  INR: 2,
  IQD: 3,
  IRR: 0,
  ISK: 0,
  JMD: 2,
  JOD: 3,
  JPY: 0,
  KES: 2,
  KGS: 2,
  KHR: 2,
  KMF: 0,
  KPW: 0,
  KRW: 0,
  KWD: 3,
  KYD: 2,
  KZT: 2,
  LAK: 0,
  LBP: 0,
  LKR: 2,
  LRD: 2,
  LSL: 2,
  LTL: 2,
  LVL: 2,
  LYD: 3,
  MAD: 2,
  MDL: 2,
  MGA: 0,
  MKD: 0,
  MMK: 0,
  MNT: 2,
  MOP: 2,
  MRO: 0,
  MUR: 2,
  MVR: 2,
  MWK: 2,
  MXV: 2,
  MYR: 2,
  MZN: 2,
  NAD: 2,
  NGN: 2,
  NIO: 2,
  NOK: 2,
  NPR: 2,
  NZD: 2,
  OMR: 3,
  PAB: 2,
  PEN: 2,
  PGK: 2,
  PHP: 2,
  PKR: 2,
  PLN: 2,
  PYG: 0,
  QAR: 2,
  RON: 2,
  RSD: 2,
  RUB: 2,
  RWF: 0,
  SAR: 2,
  SBD: 2,
  SCR: 2,
  SDG: 2,
  SEK: 2,
  SGD: 2,
  SHP: 2,
  SLL: 0,
  SOS: 2,
  SRD: 2,
  SSP: 2,
  STD: 0,
  SYP: 2,
  SZL: 2,
  THB: 2,
  TJS: 2,
  TMT: 2,
  TND: 3,
  TOP: 2,
  TRY: 2,
  TTD: 2,
  TWD: 2,
  TZS: 2,
  UAH: 2,
  UGX: 2,
  USD: 2,
  USN: 2,
  USS: 2,
  UYI: 0,
  UYU: 2,
  UZS: 2,
  VEF: 2,
  VND: 0,
  VUV: 0,
  WST: 2,
  XAF: 0,
  XAG: 0,
  XAU: 0,
  XBA: 0,
  XBB: 0,
  XBC: 0,
  XBD: 0,
  XCD: 2,
  XDR: 0,
  XFU: 0,
  XOF: 0,
  XPD: 0,
  XPF: 0,
  XPT: 0,
  XTS: 0,
  XXX: 0,
  YER: 2,
  ZAR: 2,
  ZMW: 2,
};

export const all_gca_currency = {
  AED: {
    iso_name: "AED",
    full_name: "United Arab Emirates Dirham",
    type: "SECONDARY",
  },
  AUD: {
    iso_name: "AUD",
    full_name: "Australian Dollar",
    type: "PRIMARY",
  },
  BGN: {
    iso_name: "BGN",
    full_name: "Bulgarian Lev",
    type: "SECONDARY",
  },
  BHD: {
    iso_name: "BHD",
    full_name: "Bahraini Dinar",
    type: "SECONDARY",
  },
  CAD: {
    iso_name: "CAD",
    full_name: "Canadian Dollar",
    type: "PRIMARY",
  },
  CHF: {
    iso_name: "CHF",
    full_name: "Swiss Franc",
    type: "SECONDARY",
  },
  CNY: {
    iso_name: "CNY",
    full_name: "Chinese Yuan (Offshore)",
    type: "SECONDARY",
  },
  CZK: {
    iso_name: "CZK",
    full_name: "Czech Republic Koruna",
    type: "SECONDARY",
  },
  DKK: {
    iso_name: "DKK",
    full_name: "Danish Krone",
    type: "SECONDARY",
  },
  EUR: {
    iso_name: "EUR",
    full_name: "Euro",
    type: "PRIMARY",
  },
  GBP: {
    iso_name: "GBP",
    full_name: "British Pound Sterling",
    type: "PRIMARY",
  },
  HKD: {
    iso_name: "HKD",
    full_name: "Hong Kong Dollar",
    type: "SECONDARY",
  },
  HRK: {
    iso_name: "HRK",
    full_name: "Croatian Kuna",
    type: "SECONDARY",
  },
  HUF: {
    iso_name: "HUF",
    full_name: "Hungarian Forint",
    type: "SECONDARY",
  },
  IDR: {
    iso_name: "IDR",
    full_name: "Indonesian Rupiah",
    type: "TERTIARY",
  },
  ILS: {
    iso_name: "ILS",
    full_name: "Israeli New Sheqel",
    type: "SECONDARY",
  },
  INR: {
    iso_name: "INR",
    full_name: "Indian Rupee",
    type: "TERTIARY",
  },
  JPY: {
    iso_name: "JPY",
    full_name: "Japanese Yen",
    type: "SECONDARY",
  },
  KES: {
    iso_name: "KES",
    full_name: "Kenyan Shilling",
    type: "SECONDARY",
  },
  KWD: {
    iso_name: "KWD",
    full_name: "Kuwaiti Dinar",
    type: "SECONDARY",
  },
  MYR: {
    iso_name: "MYR",
    full_name: "Malaysian Ringgit",
    type: "TERTIARY",
  },
  NOK: {
    iso_name: "NOK",
    full_name: "Norwegian Krone",
    type: "SECONDARY",
  },
  NZD: {
    iso_name: "NZD",
    full_name: "New Zealand Dollar",
    type: "SECONDARY",
  },
  OMR: {
    iso_name: "OMR",
    full_name: "Omani Rial",
    type: "SECONDARY",
  },
  PHP: {
    iso_name: "PHP",
    full_name: "Philippine Peso",
    type: "TERTIARY",
  },
  PLN: {
    iso_name: "PLN",
    full_name: "Polish Zloty",
    type: "SECONDARY",
  },
  QAR: {
    iso_name: "QAR",
    full_name: "Qatari Rial",
    type: "SECONDARY",
  },
  RON: {
    iso_name: "RON",
    full_name: "Romanian Leu",
    type: "SECONDARY",
  },
  SAR: {
    iso_name: "SAR",
    full_name: "Saudi Riyal",
    type: "SECONDARY",
  },
  SEK: {
    iso_name: "SEK",
    full_name: "Swedish Krona",
    type: "SECONDARY",
  },
  SGD: {
    iso_name: "SGD",
    full_name: "Singapore Dollar",
    type: "SECONDARY",
  },
  THB: {
    iso_name: "THB",
    full_name: "Thai Baht",
    type: "SECONDARY",
  },
  TRY: {
    iso_name: "TRY",
    full_name: "Turkish Lira",
    type: "SECONDARY",
  },
  UGX: {
    iso_name: "UGX",
    full_name: "Ugandan Shilling",
    type: "SECONDARY",
  },
  USD: {
    iso_name: "USD",
    full_name: "United States Dollar",
    type: "PRIMARY",
  },
  ZAR: {
    iso_name: "ZAR",
    full_name: "South African Rand",
    type: "SECONDARY",
  },
};

export const currency_code_to_symbol = {
  AED: "AED",
  AFN: "L",
  ALL: "؋",
  AMD: "֏",
  ANG: "ƒ",
  AOA: "Kz",
  ARS: "$",
  AUD: "A$",
  AWG: "ƒ",
  AZN: "₼",
  BAM: "KM",
  BBD: "$",
  BDT: "৳",
  BGN: "лв",
  BHD: ".د.ب",
  BIF: "FBu",
  BMD: "$",
  BND: "B",
  BOB: "Bs",
  BRL: "R",
  BSD: "B",
  BTN: "Nu",
  BWP: "P",
  BYN: "Br",
  BZD: "BZ",
  CAD: "CA",
  CDF: "FC",
  CHF: "CHF",
  CLP: "$",
  CNH: "¥",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUP: "CUC",
  CVE: "CVE",
  CZK: "Kč",
  DJF: "Fdj",
  DKK: "kr",
  DOP: "RD",
  DZD: "دج",
  EGP: "E£",
  ERN: "Nkf",
  ETB: "Br",
  EUR: "€",
  FKP: "FK£",
  GBP: "£",
  GEL: "₾",
  GHS: "GH₵",
  GMD: "D",
  GNF: "FG",
  GTQ: "Q",
  GYD: "G",
  HKD: "HK",
  HNL: "L",
  HRK: "kn",
  HTG: "G",
  HUF: "ft",
  IDR: "Rp",
  ILS: "₪",
  INR: "₹",
  IQD: "ع.د",
  IRR: "﷼",
  ISK: "Íkr",
  JMD: "J",
  JOD: "ينار",
  JPY: "¥",
  KES: "KSh",
  KGS: "som",
  KHR: "៛",
  KMF: "CF",
  KPW: "₩",
  KRW: "₩",
  KWD: "ك",
  KYD: "CI",
  KZT: "₸",
  LAK: "₭",
  LBP: "ل.ل",
  LKR: "Rs",
  LRD: "LD",
  LSL: "L",
  LYD: "LD",
  MAD: "DH",
  MDL: "L",
  MGA: "Ar",
  MKD: "ден",
  MMK: "K",
  MNT: "₮",
  MOP: "MOP",
  MRU: "UM",
  MUR: "₨",
  MVR: "MRf",
  MWK: "K",
  MXN: "$",
  MYR: "RM",
  MZN: "MT",
  NAD: "N",
  NGN: "₦",
  NIO: "C",
  NOK: "kr",
  NPR: "Rs",
  NZD: "NZ$",
  OMR: "ر.ع",
  PAB: "B/.",
  PEN: "S/.",
  PHP: "₱",
  PKR: "Rs",
  PLN: "zł",
  PYG: "₲",
  QAR: "ر.ق",
  RON: "lei",
  RSD: "RSD",
  RUB: "₽",
  RWF: "R₣",
  SAR: "SR",
  SCR: "SR",
  SDG: "SDG",
  SEK: "kr",
  SGD: "S$",
  SLL: "Le",
  SOS: "Sh.So",
  SRD: "Sr",
  SSP: "SS£",
  STN: "Db",
  SYP: "£S",
  SZL: "L",
  THB: "฿",
  TJS: "TJS",
  TMT: "m",
  TND: "د.ت",
  TRY: "₺",
  TTD: "TT",
  TWD: "NT",
  TZS: "TSh",
  UAH: "₴",
  UGX: "USh",
  USD: "$",
  UYU: "$U",
  UZS: "som",
  VED: "Bs",
  VND: "₫",
  XAF: "FCFA",
  XCD: "$",
  XOF: "CFA",
  YER: "﷼",
  ZAR: "R",
};

export const PLAN_SCOPE_LIST = [
  {
    key: "DOMESTIC",
    value: "DOMESTIC",
  },
  {
    key: "GCA",
    value: "GCA",
  },
];

export const CONTRACTING_ENTITY_COUNTRY = [
  {
    value: "AU",
    key: "Australia",
  },
  {
    value: "SG",
    key: "Singapore",
  },
];

export const decimal_format_list = {
  2: "0,0.00",
  3: "0,0.000",
  0: "0,0",
};
