import {
  FETCH_ORG,
  SET_ORG,
  FAILED_ORG_FETCH
} from '../actions/orgAction';

const initalState = {
  fetching: false,
  organizations: {},
  error: null
};

export const orgReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case FETCH_ORG:
      return { fetching: true, organizations: {}, error: null };
    case SET_ORG:
      return { ...state, fetching: false, organizations: payload, error: null };
    case FAILED_ORG_FETCH:
      return { ...state, fetching: false, organizations: {}, error: payload };

    default:
      return state;
  }
};
