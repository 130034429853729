import {
    NEW_CONNECTOR_REQUEST,
    NEW_CONNECTOR_SUCCESS,
    // NEW_CONNECTOR_FAIL,
    CONNECTOR_RESET
  } from '../actions/connectorAction';

export const newConnectorReducer = (state = {}, { type, payload })=>{
    switch(type){
        case NEW_CONNECTOR_REQUEST:
            return {fetching: true};
        case NEW_CONNECTOR_SUCCESS:
            return {fetching: false, connectors: payload, success: true};
        // case NEW_CONNECTOR_FAIL:
        //     return {fetching: false, error: payload};
        case CONNECTOR_RESET:
            return {};
        default:
          return state;
    }
};